/* All custom global styles go here */

/* Main elements */
@font-face {
  font-family: donnesseFont;
  src: url(../assets/fonts/prompt/Prompt-Regular.ttf);
}

body {
  font-family: donnesseFont, sans-serif;
}

/* Custom Bootstrap */
.bg-dark{
  background-color: #0e4433 !important;
}


/* custom styles */

.nav-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 55px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  overflow-x: auto;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
  color: #444444;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}

.nav__link:hover {
  background-color: #096d59;
}

.nav__link--active {
  color: #009578;
}

.nav__icon {
  font-size: 20px;
}